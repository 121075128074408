<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-overlay :show="loadingState">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <ValidationProvider name="Personal Info" vid="personal_info" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="2"
                                    label-for="personal_info"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('elearning_tim.personal_info')}} <span class="text-danger">*</span>
                                </template>
                                <vue-editor
                                    id="personal_info"
                                    v-model="releaseOrder.personal_info"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></vue-editor>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Description" vid="description" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="2"
                                    label-for="description"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('globalTrans.description')}} <span class="text-danger">*</span>
                                </template>
                                <vue-editor
                                    id="description"
                                    v-model="releaseOrder.description"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></vue-editor>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { certificateDetailsStore, certificateDetailsUpdate, certificateDetail } from '../../api/routes'
import { VueEditor } from 'vue2-editor'
export default {
  name: 'FormLayout',
  props: ['item'],
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },
  created () {
    if (this.item) {
      this.getEditingData()
    }
  },
  data () {
    return {
      defaultValue: '',
      saveBtnName: this.id ? this.$t('globalTrans.save') : this.$t('globalTrans.save'),
      releaseOrder: {
        description: '',
        personal_info: '',
        circular_memo_no: this.item.circular_memo_no
      },
      seedNameList: []
    }
  },
  computed: {
        loadingState () {
          if (this.listReload) {
            return true
          } else if (this.loading) {
              return true
          } else {
            return false
          }
        }
  },
  mounted () {
    core.index()
  },
  methods: {
    // getEditingData () {
    //   const tmpData = this.$store.state.list.find(item => item.id === this.id)
    //   return JSON.parse(JSON.stringify(tmpData))
    // },
    async getEditingData () {
        const circularMemoNo = {
            circular_memo_no: this.item.circular_memo_no
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, certificateDetail, circularMemoNo)
        if (!result.success) {
          this.releaseOrder = []
        } else {
            this.releaseOrder.personal_info = result.data.personal_info
            this.releaseOrder.description = result.data.description
            this.releaseOrder.circular_memo_no = result.data.circular_memo_no
        }
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${certificateDetailsUpdate}/${this.id}`, this.releaseOrder)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, certificateDetailsStore, this.releaseOrder)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-6')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
